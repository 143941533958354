import React, { forwardRef, useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { sendEvent } from '@src/utils/amplitude';
import * as styles from './featuresBlockInfo.module.scss';
import { mixPanelService } from '../../../services/mixPanelService';
import { useSelector } from 'react-redux';

const FeaturesBlockInfo = forwardRef(
	(
		{
			name,
			title,
			description,
			className,
			style,
			videoMp4 = '',
			videoWebm = '',
			videoPoster = '',
			isHighlights = false,
			onTryBtnClick,
			tryBtnEventLocation,
			imagePath,
			difLayout = false,
			type,
		},
		ref
	) => {
		const account = useSelector((state) => state.authReducer);
		const [showVideoEl, setShowVideoEl] = useState(false);
		const intl = useIntl();

		return (
			<div className={[styles.container, className].join(' ')} style={style} ref={ref}>
				<div className={[styles.blockInfoContent, difLayout ? styles.diffContentWrapper : ''].join(' ')}>
					<div className={[styles.contentWrapper, difLayout ? styles.sticky : ''].join(' ')}>
						{/*<div className={[styles.name].join(' ')}>{name}</div>*/}

						<h2 className={styles.title}>{title}</h2>
						{description && <p className={styles.descr}>{description}</p>}
					</div>
				</div>

				<div className={styles.imageBlock}>
					{type && type === 'clipsHighlights' ? (
						<div className={styles.video}>
							<video autoPlay muted loop playsInline preload="auto">
								<source src="/video/first-screen/Clip-HD.mp4" type="video/mp4" />
								<source src="/video/first-screen/Clip-HD.webm" type="video/webm" />
							</video>
						</div>
					) : (
						<img src={imagePath} className={styles.image} />
					)}
				</div>
			</div>
		);
	}
);

export default FeaturesBlockInfo;
