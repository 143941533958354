import React, { useEffect, useState, useRef, useCallback } from 'react';
import * as styles from './features.module.scss';
import * as stylesHighlights from '../../VideoBlockInfo/videoBlockInfo.module.scss';
import { connect } from 'react-redux';
import { setLoginPopupOpen } from '@src/state/loginPopup/actions';
import { useIntl } from 'gatsby-plugin-react-intl';
import { useInView } from 'react-intersection-observer';
import useResponsive from '@src/utils/useResponsive';

import FeaturesBlockInfo from '@components/molecules/FeaturesBlockInfo';

const transPathStart = 'videolanding.presentation.';

const intersectionObserverOptions = {
	threshold: 1,
};

const Features = ({ setLoginPopupOpen }) => {
	const intl = useIntl();
	const [isDesktop, isTablet, isMobile] = useResponsive();

	const videosFolderPath = intl.locale === 'ru' ? '/video/presentation/ru/' : '/video/presentation/en/';

	const imagesFolderPath =
		intl.locale === 'ru'
			? `/landing/features${isMobile ? '/mobile' : ''}/ru/`
			: `/landing/features${isMobile ? '/mobile' : ''}/en/`;

	const [showVideo, setShowVideo] = useState(true);
	const [videoPath, setVideoPath] = useState(videosFolderPath + 'Dashboard.webm');

	const [lastInfoBlockPaddingBottom, setLastInfoBlockPaddingBottom] = useState(0);
	const [videoTopCoord, setVideoTopCoord] = useState(100);

	const videoRef = useRef();

	const [dashboardRef, dashboardInView] = useInView(intersectionObserverOptions);
	const [highlightsRef, highlightsInView] = useInView(intersectionObserverOptions);
	const [grenadesRef, grenadesInView] = useInView(intersectionObserverOptions);
	const [replayRef, replayInView] = useInView(intersectionObserverOptions);
	const [performanceRef, performanceInView] = useInView(intersectionObserverOptions);
	const [comparisonRef, comparisonInView] = useInView(intersectionObserverOptions);
	const [prematchRef, prematchInView] = useInView(intersectionObserverOptions);
	const [overviewRef, overviewInView, overviewEntry] = useInView(intersectionObserverOptions);

	const handleTryBtnClick = useCallback(() => {
		setLoginPopupOpen(true);
	});

	const toggleVideoPath = (path) => {
		if (path === videoPath) return;
		setShowVideo(false);
		setTimeout(() => {
			setVideoPath('');
			setVideoPath(path);
			setShowVideo(true);
		}, 300);
	};

	return (
		<section id="presentation" className={styles.container}>
			<div className={[styles.inner, 'container-v'].join(' ')}>
				<div className={styles.info}>
					<FeaturesBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'progress.name' })}
						title={intl.formatMessage({ id: transPathStart + 'progress.title' })}
						description={intl.formatMessage({ id: transPathStart + 'progress.description' })}
						className={styles.videoBlockInfo}
						ref={highlightsRef}
						videoWebm={isMobile ? videosFolderPath + 'Highlights.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Highlights.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Highlights.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="2 screen"
						isHighlights={true}
						imagePath={imagesFolderPath + 'progress.webp'}
					/>
					<FeaturesBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'performance.name' })}
						title={intl.formatMessage({ id: transPathStart + 'performance.title' })}
						description={null}
						className={styles.videoBlockInfo}
						ref={dashboardRef}
						videoWebm={isMobile ? videosFolderPath + 'Dashboard.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Dashboard.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Dashboard.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="1 screen"
						imagePath={imagesFolderPath + 'performance.webp'}
					/>
					<FeaturesBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'clip.name' })}
						title={intl.formatMessage({ id: transPathStart + 'clip.title' })}
						description={null}
						className={styles.videoBlockInfo}
						ref={replayRef}
						videoWebm={isMobile ? videosFolderPath + 'Replay.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Replay.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Replay.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="4 screen"
						type="clipsHighlights"
					/>
					<FeaturesBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'replay.name' })}
						title={intl.formatMessage({ id: transPathStart + 'replay.title' })}
						description={null}
						className={styles.videoBlockInfo}
						ref={highlightsRef}
						videoWebm={isMobile ? videosFolderPath + 'Highlights.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Highlights.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Highlights.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="2 screen"
						isHighlights={true}
						imagePath={imagesFolderPath + 'replay.webp'}
					/>
					<FeaturesBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'compare.name' })}
						title={intl.formatMessage({ id: transPathStart + 'compare.title' })}
						description={null}
						className={styles.videoBlockInfo}
						ref={dashboardRef}
						videoWebm={isMobile ? videosFolderPath + 'Dashboard.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Dashboard.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Dashboard.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="1 screen"
						imagePath={imagesFolderPath + 'compare.webp'}
					/>
					<FeaturesBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'scoreboard.name' })}
						title={intl.formatMessage({ id: transPathStart + 'scoreboard.title' })}
						description={intl.formatMessage({ id: transPathStart + 'scoreboard.description' })}
						className={styles.videoBlockInfo}
						ref={highlightsRef}
						videoWebm={isMobile ? videosFolderPath + 'Highlights.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Highlights.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Highlights.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="2 screen"
						isHighlights={true}
						imagePath={imagesFolderPath + 'scoreboard.webp'}
					/>
					<FeaturesBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'grenades.name' })}
						title={intl.formatMessage({ id: transPathStart + 'grenades.title' })}
						description={null}
						className={styles.videoBlockInfo}
						ref={highlightsRef}
						videoWebm={isMobile ? videosFolderPath + 'Highlights.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Highlights.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Highlights.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="2 screen"
						isHighlights={true}
						imagePath={imagesFolderPath + 'grenades.webp'}
					/>
					{/******/}
					{/*<FeaturesBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'bot.name' })}
						title={intl.formatMessage({ id: transPathStart + 'bot.title' })}
						description=""
						className={[styles.difLayout, styles.videoBlockInfo].join(' ')}
						ref={grenadesRef}
						videoWebm={isMobile ? videosFolderPath + 'Granade.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Granade.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Granade.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="3 screen"
						imagePath={imagesFolderPath + 'bot.webp'}
						difLayout={true}
					/>
					
					<FeaturesBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'grenades.name' })}
						title={intl.formatMessage({ id: transPathStart + 'grenades.title' })}
						description={intl.formatMessage({ id: transPathStart + 'grenades.description' })}
						className={styles.videoBlockInfo}
						ref={highlightsRef}
						videoWebm={isMobile ? videosFolderPath + 'Highlights.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Highlights.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Highlights.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="2 screen"
						isHighlights={true}
						imagePath={imagesFolderPath + 'grenades.webp'}
					/>*/}
					{/******/}
					{/*<FeaturesBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'highlights.name' })}
						title={intl.formatMessage({ id: transPathStart + 'highlights.title' })}
						description={intl.formatMessage({ id: transPathStart + 'highlights.description' })}
						className={styles.videoBlockInfo}
						ref={highlightsRef}
						videoWebm={isMobile ? videosFolderPath + 'Highlights.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Highlights.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Highlights.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="2 screen"
						isHighlights={true}
						imagePath={imagesFolderPath + 'performance.webp'}
					/>
					<VideoBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'performance.name' })}
						title={intl.formatMessage({ id: transPathStart + 'performance.title' })}
						description={intl.formatMessage({ id: transPathStart + 'performance.description' })}
						className={styles.videoBlockInfo}
						ref={performanceRef}
						videoWebm={isMobile ? videosFolderPath + 'Perfomance.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Perfomance.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Perfomance.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="5 screen"
					/>
					<VideoBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'comparison.name' })}
						title={intl.formatMessage({ id: transPathStart + 'comparison.title' })}
						description={intl.formatMessage({ id: transPathStart + 'comparison.description' })}
						className={styles.videoBlockInfo}
						ref={comparisonRef}
						videoWebm={isMobile ? videosFolderPath + 'Sravnenie.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Sravnenie.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Sravnenie.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="6 screen"
					/>
					<VideoBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'prematch.name' })}
						title={intl.formatMessage({ id: transPathStart + 'prematch.title' })}
						description={intl.formatMessage({ id: transPathStart + 'prematch.description' })}
						className={styles.videoBlockInfo}
						ref={prematchRef}
						videoWebm={isMobile ? videosFolderPath + 'Prematch.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Prematch.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Prematch.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="7 screen"
					/>
					<VideoBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'overview.name' })}
						title={intl.formatMessage({ id: transPathStart + 'overview.title' })}
						description={intl.formatMessage({ id: transPathStart + 'overview.description' })}
						className={styles.videoBlockInfo}
						ref={overviewRef}
						videoWebm={isMobile ? videosFolderPath + 'MyMatches.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/MyMatches.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/MyMatches.jpg'}
						style={{ paddingBottom: isMobile ? 0 : lastInfoBlockPaddingBottom }}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="8 screen"
					/>{' '}
					*/}
				</div>
				{/* {!isMobile && (
					<div className={styles.blockVideo}>
						{videoPath === 'highlights' ? (
							<> */}
				{/* <iframe
									ref={videoRef}
									className={styles.video}
									style={{ opacity: showVideo ? 1 : 0, top: videoTopCoord }}
									width="1020"
									height="573"
									src="https://www.youtube-nocookie.com/embed/BuoQY8_Z1pY?controls=0&loop=1&autoplay=1&mute=1&vq=hd720&enablejsapi=1&fs=0&loop=1&modestbranding=1&playlist=BuoQY8_Z1pY&playsinline=1&rel=0"
									title="Scope.gg Highlights"
									frameBorder="0"
									allow="accelerometer; autoplay; encrypted-media; gyroscope"
									allowFullScreen
								/> */}
				{/* </>
						) : ( */}
				{/* // <video
							// 	src={videoPath}
							// 	className={styles.video}
							// 	style={{ opacity: showVideo ? 1 : 0, top: videoTopCoord }}
							// 	loop
							// 	muted
							// 	autoPlay
							// 	ref={videoRef}
							// />
							<img src={imagesFolderPath + 'replay.webp'} className={styles.video} />
						)}
						<div style={{ height: '100%' }}></div> */}
				{/* </div>
				)} */}
			</div>
		</section>
	);
};

const mapDispatchToProps = (dispatch) => ({
	setLoginPopupOpen: (payload) => dispatch(setLoginPopupOpen(payload)),
});

export default connect(null, mapDispatchToProps)(Features);
