import React from 'react';
import * as styles from './materials.module.scss';
import LoginActions from '@components/molecules/LoginActions';
import { useIntl } from 'gatsby-plugin-react-intl';

import FaceitImage from '/static/landing/materials/faceit.webp';
import DemoImage from '/static/landing/materials/demo.webp';
import SoundImage from '/static/landing/materials/sound.webp';
import MouseImage from '/static/landing/materials/mouse.webp';

import FaceitImageEn from '/static/landing/materials/en/faceit.webp';
import DemoImageEn from '/static/landing/materials/en/demo.webp';
import GraphicsImage from '/static/landing/materials/en/graphics.webp';
import MouseImageEn from '/static/landing/materials/en/mouse.webp';

const Materials = () => {
	const intl = useIntl();

	const MATERIALS_LINKS = [
		{
			id: 1,
			link: {
				en: 'https://scope.gg/guides/faceit-en/',
				ru: 'https://scope.gg/guides/ru/faceit-ru/',
			},
			src: intl.locale === 'ru' ? FaceitImage : FaceitImageEn,
			title: 'landing.materials.materialOne',
		},
		{
			id: 2,
			link: {
				en: 'https://scope.gg/guides/csgo-demo-guide-en/',
				ru: 'https://scope.gg/guides/ru/csgo-demo-guide-ru/',
			},
			src: intl.locale === 'ru' ? DemoImage : DemoImageEn,
			title: 'landing.materials.materialTwo',
		},
		{
			id: 3,
			link: {
				en: 'https://scope.gg/guides/best-graphics-settings-for-max-fps-in-cs2-ultimate-guide-en/',
				ru: 'https://scope.gg/guides/ru/all-about-sounds-settings-in-cs2-ru/',
			},
			src: intl.locale === 'ru' ? SoundImage : GraphicsImage,
			title: 'landing.materials.materialThree',
		},
		{
			id: 4,
			link: {
				en: 'https://scope.gg/guides/acceleration_en/',
				ru: 'https://scope.gg/guides/ru/acceleration_ru/',
			},
			src: intl.locale === 'ru' ? MouseImage : MouseImageEn,
			title: 'landing.materials.materialFour',
		},
	];

	return (
		<section className={styles.container}>
			<h2 className={styles.title}>{`${intl.formatMessage({ id: 'landing.materials.title' })}:`}</h2>

			<div className={styles.materialsWrapper}>
				{MATERIALS_LINKS.map((item) => (
					<div key={item.id} className={styles.materialBlock}>
						<a href={item.link[intl.locale === 'ru' ? 'ru' : 'en']} target="_blank" rel="noopener noreferrer">
							<img src={item.src} className={styles.materialImage} />
						</a>
						<span className={styles.materialDescription}>{intl.formatMessage({ id: item.title })}</span>
					</div>
				))}
			</div>
		</section>
	);
};

export default Materials;
