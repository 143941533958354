import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { connect, useSelector } from 'react-redux';
import { sendEvent, sendIdentify } from '../utils/amplitude';
import { PAGE_LOAD_LOG_EVENT_DELAY } from '../utils/constants';
import { mixPanelService } from '../services/mixPanelService';
import { isBrowser } from '../utils/ssr';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import LandingLayout from '@src/layouts/LandingNew/LandingLayoutNew';
import { getMicromarkingScriptByLocale } from '../utils/seo';
import OldLandingLayout from '../layouts/OldLanding/OldLandingLayout';
import Cookie from 'js-cookie';

const Home = () => {
	const account = useSelector((state) => state.authReducer);
	const intl = useIntl();

	const twitterCardImageLink = {
		ru: 'https://app.scope.gg/static/meta-images/ru/landing.jpg',
		en: 'https://app.scope.gg/static/meta-images/en/landing.jpg',
		fr: 'https://app.scope.gg/static/meta-images/en/landing.jpg',
		es: 'https://app.scope.gg/static/meta-images/en/landing.jpg',
		pt: 'https://app.scope.gg/static/meta-images/en/landing.jpg',
	};

	const canonicalLinks = useMemo(() => {
		if (isBrowser) {
			const location = window.location.origin;

			return {
				ru: `${location}/ru`,
				en: `${location}`,
				fr: `${location}/fr`,
				es: `${location}/es`,
				pt: `${location}/pt`,
			};
		}

		return {
			ru: 'https://scope.gg/ru',
			en: 'https://scope.gg',
			fr: 'https://scope.gg/fr',
			es: 'https://scope.gg/es',
			pt: 'https://scope.gg/pt',
		};
	}, []);

	const hreflangLinks = useMemo(() => {
		if (isBrowser) {
			const location = window.location.origin;

			return {
				ru: location,
				en: `${location}/ru/`,
				fr: `${location}/fr/`,
				es: `${location}/es/`,
				pt: `${location}/pt/`,
			};
		}

		return {
			ru: 'https://scope.gg/',
			en: 'https://scope.gg/ru/',
			fr: 'https://scope.gg/fr/',
			es: 'https://scope.gg/es/',
			pt: 'https://scope.gg/pt/',
		};
	}, []);

	useEffect(() => {
		setTimeout(() => {
			const optimizeExpirementData = Cookies.get('_gaexp');

			if (optimizeExpirementData && optimizeExpirementData.includes('AG3Xjo3STG6xgMqZfvwOHg')) {
				const optimizeExpirementVariant = optimizeExpirementData.at(-1);

				sendIdentify('user_properties', {
					experiment_id: 'AG3X1jo3STG6xgMqZfvwOHg',
					experiment_variant: optimizeExpirementVariant,
				});
			}

			sendEvent('Loadpage New Landing', {
				Language: intl.locale,
			});
		}, PAGE_LOAD_LOG_EVENT_DELAY);
	}, []);

	useEffect(() => {
		const url = new URL(window.location.href);
		const utmObject = {};
		const utmInitialSourceCookie = Cookie.get('initial-utm-source-user');
		const utmInitialCampaignCookie = Cookie.get('initial-utm-campaign-user');
		const utmInitialContentCookie = Cookie.get('initial-utm-content-user');
		const utmInitialMediumCookie = Cookie.get('initial-utm-medium-user');

		if (!utmInitialSourceCookie) {
			utmObject.utm_initial_source = url.searchParams.get('utm_source') | '-';
			Cookie.set('initial-utm-source-user', true);
		}

		if (!utmInitialCampaignCookie) {
			utmObject.utm_initial_source = url.searchParams.get('utm_campaign') | '-';
			Cookie.set('initial-utm-campaign-user', true);
		}

		if (!utmInitialContentCookie) {
			utmObject.utm_initial_source = url.searchParams.get('utm_content') | '-';
			Cookie.set('initial-utm-content-user', true);
		}

		if (!utmInitialMediumCookie) {
			utmObject.utm_initial_source = url.searchParams.get('utm_medium') | '-';
			Cookie.set('initial-utm-medium-user', true);
		}

		setTimeout(() => {
			mixPanelService.setProperty(
				{
					set: {
						Language: intl.locale,
						...utmObject,
					},
				},
				account?.userData?.steamAccountId || null
			);
			mixPanelService.sendEvent(
				{
					event: 'Loadpage Landing',
					properties: {
						Languages: intl.locale,
					},
				},
				account?.userData?.steamAccountId || null
			);
		}, PAGE_LOAD_LOG_EVENT_DELAY);
	}, [account]);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				{process.env.GATSBY_ACTIVE_ENV !== 'production' ? <meta name="robots" content="noindex, nofollow" /> : null}
				<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
				<meta name="facebook-domain-verification" content="bpvvsvqocb18kyodlluk1feteplh4z" />
				<meta property="fb:app_id" content="257953674358265" />
				<meta name="format-detection" content="telephone=no" />
				<meta httpEquiv="x-dns-prefetch-control" content="on" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>{intl.formatMessage({ id: 'seo.index.title' })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'seo.index.desc' })} />
				<meta property="og:url" content="https://scope.gg" />
				<meta property="og:title" content={intl.formatMessage({ id: 'seo.index.og|title' })} />
				<meta property="og:site_name" content="SCOPE.GG" />
				<meta property="og:description" content={intl.formatMessage({ id: 'seo.index.og|desc' })} />
				<meta property="og:image" content={twitterCardImageLink[intl.locale]} />
				<meta property="og:image:secure_url" content={twitterCardImageLink[intl.locale]} />
				<meta property="og:image:type" content="image/jpg" />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />

				<meta name="twitter:site" content="@scope_gg" />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content={intl.formatMessage({ id: 'seo.index.twitter|title' })} />
				<meta name="twitter:description" content={intl.formatMessage({ id: 'seo.index.twitter|desc' })} />
				<meta name="twitter:image" content={twitterCardImageLink[intl.locale]} />
				<link rel="icon" href="/favicon.ico" />

				{intl.locale === 'en' ? (
					<link rel="canonical" href="https://scope.gg/" />
				) : (
					<link rel="canonical" href={`https://scope.gg/${intl.locale}/`} />
				)}

				<link rel="alternate" hrefLang="x-default" href="https://scope.gg" />
				<link rel="alternate" hrefLang="en" href="https://scope.gg" />
				<link rel="alternate" hrefLang="fr" href="https://scope.gg/fr/" />
				<link rel="alternate" hrefLang="pt" href="https://scope.gg/pt/" />
				<link rel="alternate" hrefLang="es" href="https://scope.gg/es/" />
				<link rel="alternate" hrefLang="ru" href="https://scope.gg/ru/" />

				<script type="application/ld+json">{getMicromarkingScriptByLocale(intl.locale)}</script>
				{/* <link rel="canonical" href={canonicalLinks[intl.locale]} />
				{Object.keys(hreflangLinks).map((lang) => (
					<link key={lang} rel="alternate" hrefLang={hreflangLinks[lang]} />
				))} */}
			</Helmet>
			<LandingLayout />
		</>
	);
};

const mapDispatchToProps = () => ({});

export default connect(null, mapDispatchToProps)(Home);
