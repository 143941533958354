import React, { useState, useRef, useEffect } from 'react';
import * as styles from './videoReviews.module.scss';
import { useIntl } from 'gatsby-plugin-react-intl';
import SvgArrow from '@components/atoms/SvgArrow';

import PlayIcon from '@src/assets/icons/play.svg';

const viedoLinks = {
	ru: {
		first: {
			title: 'Murzofix',
			poster: '/video/reviews/assets/Murzofix.png',
			src: {
				webm: '/video/reviews/ru/promo.webm',
				mp4: '/video/reviews/ru/promo.mp4',
			},
		},
		second: {
			title: 'Fenya',
			poster: '/video/reviews/assets/Fenya.png',
			src: {
				webm: '/video/reviews/ru/promo2.webm',
				mp4: '/video/reviews/ru/promo2.mp4',
			},
		},
	},
	en: {
		first: {
			title: 'NadeKing',
			poster: '/video/reviews/assets/NadeKing.png',
			src: {
				webm: '/video/reviews/en/promo.webm',
				mp4: '/video/reviews/en/promo.mp4',
			},
		},
		second: {
			title: 'd0cc',
			poster: '/video/reviews/assets/d0cc.png',
			src: {
				webm: '/video/reviews/en/promo2.webm',
				mp4: '/video/reviews/en/promo2.mp4',
			},
		},
	},
};

const ButtonVideoWrapper = ({ children, onClick, touched }) => {
	if (touched) return children;

	return (
		<button className={styles.buttonWrapper} onClick={onClick}>
			<img className={styles.buttonIcon} src={PlayIcon} />
			{children}
		</button>
	);
};

const VideoReviews = () => {
	const intl = useIntl();

	const videoData = intl.locale === 'ru' ? viedoLinks.ru : viedoLinks.en;

	const firstVideoRef = useRef(null);
	const secondVideoRef = useRef(null);

	const [firstVideoTouched, setFirstVideoTouched] = useState(false);
	const [secondVideoTouched, setSecondVideoTouched] = useState(false);

	useEffect(() => {
		if (!firstVideoTouched) return;

		if (firstVideoRef.current) {
			firstVideoRef.current.play();
		}
	}, [firstVideoTouched]);

	useEffect(() => {
		if (!secondVideoTouched) return;

		if (secondVideoRef.current) {
			secondVideoRef.current.play();
		}
	}, [secondVideoTouched]);

	return (
		<section className={styles.container}>
			<div className={styles.wrapper}>
				<span className={styles.mediaText}>{`${intl.formatMessage({ id: 'landing.videoReviews.title' })}:`}</span>

				<div className={styles.videosWrapper}>
					<div className={styles.videoBlock}>
						<ButtonVideoWrapper touched={firstVideoTouched} onClick={() => setFirstVideoTouched(true)}>
							<video
								ref={firstVideoRef}
								className={styles.videoBlock2}
								controls={firstVideoTouched}
								preload
								poster={videoData.first.poster}
							>
								<source src={videoData.first.src.webm} type="video/webm" />
								<source src={videoData.first.src.mp4} type="video/mp4" />
								Your browser does not support the video tag.
							</video>
						</ButtonVideoWrapper>
						<span className={styles.videoAuthorText}>{videoData.first.title}</span>
					</div>

					<div className={styles.videoBlock}>
						<ButtonVideoWrapper touched={secondVideoTouched} onClick={() => setSecondVideoTouched(true)}>
							<video
								ref={secondVideoRef}
								className={styles.videoBlock2}
								controls={secondVideoTouched}
								autoplay={secondVideoTouched}
								preload
								poster={videoData.second.poster}
							>
								<source src={videoData.second.src.webm} type="video/webm" />
								<source src={videoData.second.src.mp4} type="video/mp4" />
								Your browser does not support the video tag.
							</video>
						</ButtonVideoWrapper>
						<span className={styles.videoAuthorText}>{videoData.second.title}</span>
					</div>
				</div>
			</div>
		</section>
	);
};

export default VideoReviews;
