import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import * as styles from './searchPlayer.module.scss';
import { useKey } from 'react-use';
import { useIntl } from 'gatsby-plugin-react-intl';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { setLoginPopupOpen } from '@src/state/loginPopup/actions';

import { sendEvent } from '@src/utils/amplitude';

import arrowRight from '@src/assets/images/landing/arrowRightInCircle.svg';
import arrowDown from '@src/assets/images/landing/arrow-down.svg';
import trustpilotImg from '@src/assets/images/video-landing/trustpilot-rate.png';

import PlayerSearch from '@components/molecules/PlayerSearch';

import { mixPanelService } from '../../../services/mixPanelService';

const SearchPlayer = ({ isLoginPopupOpen, setLoginPopupOpen, onClose }) => {
	const account = useSelector((state) => state.authReducer);
	const intl = useIntl();
	const [showFixedTryBtn, setShowFixedTryBtn] = useState(false);

	useKey('Escape', onClose);

	// useEffect(() => {
	// 	const handleScroll = (e) => {
	// 		if (window.scrollY > 500) {
	// 			setShowFixedTryBtn(true);
	// 		} else {
	// 			setShowFixedTryBtn(false);
	// 		}
	// 	};

	// 	window.addEventListener('scroll', handleScroll);

	// 	return () => window.removeEventListener('scroll', handleScroll);
	// }, []);

	return (
		<section className={[styles.container, 'container-v'].join(' ')}>
			<PlayerSearch />

			<div className={styles.closeWrapper}>
				<button className={styles.closeButton} onClick={onClose}>
					<div className={styles.buttonContent}>
						<div className={styles.buttonKey}>
							<span>ESC</span>
						</div>
						<span className={styles.buttonDescription}>{intl.formatMessage({ id: 'landing.playerSearch.back' })}</span>
					</div>
				</button>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	isLoginPopupOpen: state.loginPopupReducer.isOpen,
});

const mapDispatchToProps = (dispatch) => ({
	setLoginPopupOpen: (payload) => dispatch(setLoginPopupOpen(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPlayer);
