import React, { useState, useRef, useEffect, useMemo } from 'react';
import * as styles from './faq.module.scss';
import { useIntl } from 'gatsby-plugin-react-intl';
import SvgArrow from '@components/atoms/SvgArrow';

import { AccordionNew } from '@components/atoms';

const faq = {
	list: [
		{
			id: '1',
			question: 'landing.faq.questionOne',
			answer: 'landing.faq.answerOne',
		},
		{
			id: '2',
			question: 'landing.faq.questionTwo',
			answer: 'landing.faq.answerTwo',
		},
		{
			id: '3',
			question: 'landing.faq.questionThree',
			answer: 'landing.faq.answerThree',
		},
		{
			id: '4',
			question: 'landing.faq.questionFour',
			answer: 'landing.faq.answerFour',
		},
		{
			id: '5',
			question: 'landing.faq.questionFive',
			answer: 'landing.faq.answerFive',
		},
		{
			id: '6',
			question: 'landing.faq.questionSix',
			answer: 'landing.faq.answerSix',
		},
	],
};

const faqEn = {
	list: [
		{
			id: '7',
			question: 'How to See CS2 Stats?',
			answer:
				'To view your CS2 stats on Scope.gg, simply log in to your account, navigate to the "CS2 Stats" section, and enter your Steam ID or link your Steam account. Your stats will be displayed, including detailed match history, performance metrics, and personalized insights.',
		},
		{
			id: '8',
			question: 'How do I troubleshoot issues with my match history not updating?',
			answer:
				'If your match history isn\u2019t updating, try the following steps:\n\n1.Ensure your Steam account is linked correctly.\n2.Verify that your game privacy settings allow third-party access.\n3.Refresh the match history page.\n4.Clear your browser cache and cookies.\n5.If the issue persists, contact our support team for further assistance.',
		},
		{
			id: '9',
			question: 'Can I use Scope.gg with other games besides CS2?',
			answer:
				'Currently, Scope.gg is designed specifically for CS2. We are continuously exploring the possibility of expanding our support to other games in the future. Stay tuned for updates!',
		},
		{
			id: '10',
			question: 'What features are available without creating an account?',
			answer: 'Without creating an account, you can:',
		},
		{
			id: '11',
			question: 'How can I delete my account and data?',
			answer:
				'To delete your account and data, follow these steps:\n\n1.Log in to your account.\n2.Go to the account settings section.\n3.Select "Delete Account" and follow the prompts.\n4.Confirm your decision. Your data will be permanently removed from our servers.',
		},
		{
			id: '12',
			question: 'Is there a mobile app for Scope.gg?',
			answer:
				'As of now, Scope.gg does not have a dedicated mobile app. However, our website is fully optimized for mobile browsers, allowing you to access your stats and other features on the go.',
		},
		{
			id: '13',
			question: 'How often is my match data updated?',
			answer:
				'Your match data is updated in real-time as soon as we receive the information from Steam. Typically, it takes a few minutes after a match ends for the data to be processed and displayed on Scope.gg.',
		},
		{
			id: '14',
			question: 'Can I share my match stats with friends?',
			answer:
				'Yes, you can share your match stats with friends! Each match has a unique shareable link. Simply copy the link from your match details page and share it via your preferred method.',
		},
		{
			id: '15',
			question: 'What are the subscription plans and their benefits?',
			answer: 'Scope.gg offers several subscription plans, each with its own set of benefits:',
		},
		{
			id: '16',
			question: 'How do I report a bug or technical issue?',
			answer:
				'To report a bug or technical issue, please use our contact form or send an email to support@scope.gg. Provide as much detail as possible, including screenshots and steps to reproduce the issue, to help us resolve it quickly.',
		},
		{
			id: '17',
			question: 'Are there any tutorials for using Scope.gg tools effectively?',
			answer:
				'Yes, we have a variety of tutorials available on our website. Visit the "Help" or "Tutorials" section to find guides, videos, and tips on how to make the most of Scope.gg’s tools and features.\n\nFeel free to modify or expand upon these answers based on any specific details or tone preferred for your website.',
		},
	],
};

const FAQ = () => {
	const intl = useIntl();

	const list = useMemo(() => {
		return [...faq.list, ...(intl.locale === 'en' ? faqEn.list : [])];
	}, [intl.locale]);

	return (
		<section className={styles.container}>
			<span className={styles.title}>FAQ</span>
			<div className={styles.wrapper}>
				{list.map((item) => (
					<AccordionNew
						key={item.id}
						id={item.id}
						title={item.question}
						description={item.answer}
						values={item.values}
					/>
				))}
			</div>
		</section>
	);
};

export default FAQ;
